import React from "react";

export default function IFrame() {
    return (
        <div id="iframe" >
            <iframe 
                width="100%"
                height="650"
                src="https://staging.simdefi.pages.dev/"></iframe>
        </div>
    );
}