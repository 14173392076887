import React, { useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
// import { getContacts } from "../contacts";
import { Mixpanel } from '../mixpanel'

export default function Root() {
  
  useEffect(() => {
    // Mixpanel.track('open home page');
  }, []);

  const onSubmit = (event) => {
    //Mixpanel.track_form()
  }

  const onContactClicked = (event, contactId) => {
    const eventName = 'Veiw Contact';
    Mixpanel.track(eventName, {contactId});
  }

    return (
      <>
        <div id="sidebar">
          <h1>React Router Contacts</h1>
          <div>
            <form id="search-form" role="search">
              <input
                id="q"
                aria-label="Search contacts"
                placeholder="Search"
                type="search"
                name="q"
              />
              <div
                id="search-spinner"
                aria-hidden
                hidden={true}
              />
              <div
                className="sr-only"
                aria-live="polite"
              ></div>
            </form>
            <form method="post" onSubmit={onSubmit}>
              <button type="submit">New</button>
            </form>
          </div>
          <nav>
            <ul>
              <li>
                <Link to={`contacts/1`} onClick={(event) => onContactClicked(event, 1)}>Your Name</Link>
              </li>
              <li>
                <Link to={`contacts/2`} onClick={(event) => onContactClicked(event, 2)}>Your Friend</Link>
              </li>
              <li>
                <Link to={`iframe`}>iFrame</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div id="detail"><Outlet /></div>
      </>
    );
  }
  