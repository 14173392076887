import mixpanel from 'mixpanel-browser';

mixpanel.init('0e03adfa664b74a8f9dfb5a98ac483c2', {debug: true});

let actions = {
    track: (name, props) => {
        mixpanel.track(name, props);
    },
    track_links: (query, name, props) => {
        mixpanel.track_links(query, name, props);
    },
    track_forms: (query, name, props) => {
        mixpanel.track_forms(query, name, props);
    }
}

export let Mixpanel = actions;